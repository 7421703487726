/* .details-page .custom-tab-title::marker {
    content: "";
    font-size: 9px;
    float: left;
    margin-top: 10px;
    margin-left: -17px;
    color: #ffffff00;
  }

  .react-bootstrap .nav-link.active {
    background-color: #58b7aa !important;
}

.nav-pills .nav-link.active {
    color: white;
    background-color: #58b7aa !important;
}

.nav-link{
    color: white; 
}


 */

.nav {
  /* --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ; */
  --bs-nav-link-color: none;
  --bs-nav-link-hover-color: none;
  --bs-nav-link-disabled-color: none;
  /* display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; */
}
::marker {
  content: "► ";
}

.no-bullet {
  list-style-type: none;
  padding: 0;
  color: white;
}

.links-vertical {
  color: white;
}

.no-bullet ::marker {
  content: " ";
  font-size: 9px;
  float: left;
  margin-top: 10px;
  margin-left: -17px;
  color: #ffffff;
}

.background-image-container {
  position: relative;
  background-image: url("https://media1.ubeetu.com/images/videos/1638257242title.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh; /* Set full height */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.content-container {
  position: relative;
  z-index: 1;
}

/* Your existing tab styles */
.react-bootstrap .nav-link.active {
  background-color: #008dc4 !important;
}

.nav-pills .nav-link.active {
  color: rgb(255, 255, 255);
  background-color: #008dc4 !important;
}

.nav-link {
  color: white;
}

.loader {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  /* background: #e20788; */
  box-shadow: 0 0 0 0 #008dc4;
  animation: l1 1s infinite;
}
@keyframes l1 {
    100% {box-shadow: 0 0 0 30px #0000}
}

.rainbow-btn {
  color: white;
  cursor: pointer;
  position: relative;
  padding: 0px 30px;
  text-decoration: none;
  border-radius: 8px;
  transition: .5s;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  animation: rainbow 8s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

.rainbow-btn:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 8px;
  opacity: .5;
  transition: 0.5s;
  animation: rainbow 8s linear infinite;
  filter: blur(5px);
}

.rainbow-btn:hover:before {
  filter: blur(20px);
  opacity: 1;
}